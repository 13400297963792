// src/templates/pages/NewsEventsListing.js

import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import ResourcesHeader from '../../components/ResourcesHeader'
import Pagination from '../../components/Pagination.js'
import parse from 'html-react-parser'
import ArrowSVG from '../../images/icon-arrow-right.svg'
import ArrowDiagonalSVG from '../../images/icon-arrow-diagonal.svg'

// Return a custom formatted date block range
// May 16, 2022 - only start data
// May 16-18, 2022 - end date in same month
// May 16 - April 22, 2022 - end date in different month, same year
// May 16, 2022 - June 14, 2023 - end date in different year

function fancyDateRange(
  start_day,
  start_month,
  start_year,
  end_day,
  end_month,
  end_year
) {
  // if no end date
  if (end_day == null) {
    return (
      <span>
        {start_month} {start_day}, {start_year}
      </span>
    )
  }

  // if end date, lets get fancy
  const sameDay = start_day === end_day
  const sameMonth = start_month === end_month
  const sameYear = start_year === end_year

  return (
    <span>
      {start_month} {start_day}
      {!sameYear ? <>, {start_year}</> : null}
      {sameMonth && sameYear && !sameDay ? <> - {end_day}</> : null}
      {(sameMonth && !sameYear) || (!sameMonth && sameYear) ? (
        <>
          {' '}
          - {end_month} {end_day}
        </>
      ) : null}
      , {end_year}
    </span>
  )
}

function NewsEventsListing(props) {
  const pageData = props.data.wpPage.template.newsEventsPage
  const newsData = props.data.allWpNews

  const [eventList, setEventList] = useState(null)
  useEffect(() => {
    if (props.pageContext.currentPage === 1) {
      fetch(`${process.env.GATSBY_EVENTS_ROUTE}`)
        .then(response => response.json())
        .then(resultData => {
          if (resultData) {
            setEventList(buildEventList(resultData))
          } else {
            setEventList(<li>There are no upcoming events</li>)
          }
        })
    }
  }, [props.pageContext.currentPage])

  const buildEventList = function (data) {
    if (data !== null) {
      return data.map((node, index) => {
        return (
          <li
            key={index}
            className="grid grid-cols-1 bg-white shadow-card md:grid-cols-[13rem_1fr]"
          >
            <div className="flex flex-col bg-boynton-a11y p-responsive-xl-32 text-white md:h-full md:min-h-[12rem] md:items-center md:justify-center md:p-0 md:text-center">
              <div className="event-day hidden md:block">
                <span className="block font-light text-[6rem] leading-none">
                  {node.start_day}
                </span>
                <span>
                  {node.start_month} {node.start_year}
                </span>
              </div>

              <div className="mobile-date md:hidden">
                <span className="text-nav uppercase text-white">
                  {fancyDateRange(
                    node.start_day,
                    node.start_month,
                    node.start_year,
                    node.end_day,
                    node.end_month,
                    node.end_year
                  )}
                </span>
              </div>
            </div>

            <div className="event-text space-y-responsive-xl-12 py-responsive-xl-24 px-responsive-xl-32">
              {node.title && (
                <h3 className="font-regular text-xl">{parse(node.title)}</h3>
              )}

              <div className="flex-col items-center space-x-responsive-xl-8 text-nav">
                <span className="uppercase">{node.location}</span>
                <span className="text-granite-200">|</span>
                <span className="uppercase text-boynton-a11y">
                  {fancyDateRange(
                    node.start_day,
                    node.start_month,
                    node.start_year,
                    node.end_day,
                    node.end_month,
                    node.end_year
                  )}
                </span>
              </div>
              {node.description && <p>{parse(node.description)}</p>}
              <a
                href={node.event_url}
                rel="nofollow"
                className="btn-arrow inline-flex items-center font-medium text-nav"
              >
                Read Now
                <ArrowSVG className="icon ml-responsive-xl-4 fill-fuscia-full" />
              </a>
            </div>
          </li>
        )
      })
    } else {
      return <li>There are no results for this query</li>
    }
  }

  return (
    <Layout>
      <Seo post={props.data.wpPage} />

      <ResourcesHeader
        title={props.data.wpPage.title}
        description={pageData.pageDescription}
      />

      {/* only show events on first page of news results */}
      {props.pageContext.currentPage === 1 && (
        <section className="events scroll-trigger">
          <div className="container">
            {pageData.eventsHeadline && (
              <h2 className="scroll-target mb-responsive-xl-64 text-center font-medium text-nav uppercase tracking-wider">
                {parse(pageData.eventsHeadline)}
              </h2>
            )}

            <ul className="scroll-target space-y-responsive-xl-32">
              {eventList}
            </ul>
          </div>
        </section>
      )}

      <section className="news scroll-trigger">
        <div className="container">
          {pageData.newsHeadline && (
            <h2 className="scroll-target mb-responsive-xl-64 text-center font-medium text-nav uppercase tracking-wider">
              {parse(pageData.newsHeadline)}
            </h2>
          )}

          <ul className="scroll-target grid grid-cols-1 gap-responsive-xl-32 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {newsData.edges.map((node, index) => {
              return (
                <li
                  key={index}
                  className="space-y-responsive-xl-8 border-t-2 border-boynton-a11y py-responsive-xl-24"
                >
                  <p className="text-nav uppercase text-fathom-400">
                    {node.node.date}
                  </p>
                  {node.node.title && (
                    <h2 className="font-regular text-lg">
                      {parse(node.node.title)}
                    </h2>
                  )}
                  <p className="text-sm">
                    {node.node.newsContent.publicationName}
                  </p>
                  <a
                    href={node.node.newsContent.articleUrl}
                    rel="nofollow"
                    className="btn-arrow inline-flex pt-responsive-xl-32 font-medium text-nav" target="_blank"
                  >
                    Read More
                    <ArrowDiagonalSVG className="icon ml-responsive-xl-4 fill-fuscia-full" />
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      <Pagination
        currentPage={props.pageContext.currentPage}
        numPages={props.pageContext.numPages}
        pageSlug="news-events"
      />
    </Layout>
  )
}

export default NewsEventsListing

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    wpPage(template: { templateName: { eq: "News & Events Listing" } }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        ... on WpTemplate_NewsEventsListing {
          newsEventsPage {
            pageDescription
            eventsHeadline
            newsHeadline
          }
        }
      }
    }
    allWpNews(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          date(formatString: "MMMM D, YYYY")
          newsContent {
            publicationName
            articleUrl
          }
        }
      }
    }
  }
`
